type Config = {
    ENV_URL_BCK: string;
};

const config: Config = {} as Config;

let aplicationEnvironment = "prod"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/let aplicationEnvironment = \"[^\"]*\"/let aplicationEnvironment = \"$(APPLICATION_ENVIRONMENT)\"/" "$file_path"
  do not remove it */

const version = "20240730.1"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const version = \"[^\"]*\"/const version = \"$new_version\"/" "$file_path"
  do not remove it */


/* DATADOG VARIABLES */
const applicationId = "4ff856b4-58de-43a9-a475-097cca93cf13"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const applicationId = \"[^\"]*\"/const applicationId = \"$(DD_APPLICATION_ID)\"/" "$file_path"
  do not remove it */

const clientToken = "pubbf5de825c7e1ed68fd29a4a64112878a"; /* this value will be replaced by pipeline execution, 
  by the following command: sed -i "s/const clientToken = \"[^\"]*\"/const clientToken = \"$(DD_CLIENT_TOKEN)\"/" "$file_path"
  do not remove it */

const service = "abi-supply-global-digitalworkstation-mfe-shortcuts";
const site = "datadoghq.com";

  
if (aplicationEnvironment === "dev") {
    config.ENV_URL_BCK = "https://vpo-dev.ab-inbev.com/api";
} else if (aplicationEnvironment === "qa") {
    config.ENV_URL_BCK = "https://vpo-qa.ab-inbev.com/api";
} else if (aplicationEnvironment === "prod") {
    config.ENV_URL_BCK = "https://vpo.ab-inbev.com/api";
} else {
    config.ENV_URL_BCK = "https://vpo-dev.ab-inbev.com/api";
}

export {
    config,
    aplicationEnvironment,
    version,
    applicationId,
    clientToken,
    service,
    site
};
